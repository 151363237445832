import React, { useEffect, useState } from 'react'
import Header from './components/header';
import Form from './components/form';
import WorkList from './components/workList';
function App() {
  const [works,setWorks] =useState([])
  const [totalTime,setTotalTime]=useState(0)
  useEffect(()=>{
    
    if(works.length >0){
      localStorage.setItem('works',JSON.stringify(works))

       let temp=0;
      for(let i=0;i<works.length;i++){
        temp+= parseFloat(works[i].counter)
      }
      setTotalTime(temp)
      
    }


    
  },[works])

  useEffect(()=>{
   if(localStorage.getItem('works')){
    setWorks(JSON.parse(localStorage.getItem('works')))
   };
   
  },[])

  const clear=()=>{
    let pass = prompt('enter password');

    if(pass ==67890){
      alert('your data deleted !!')
      localStorage.clear('work');
      setWorks([])
      setTotalTime(0)
    }
    else{
      alert('wrong password !!');
    }
  
  }

  return (
    <div>
      <Header totalTime={totalTime}/>
      <Form works={works} setWorks={setWorks} />
      <WorkList works={works} />
      <div onClick={()=>clear()} >X</div>
    </div>
  )
}

export default App


