import React from 'react'

function WorkItem({work}) {

    const startDay = new Date(work.startTime).toLocaleDateString();
    const startTime = new Date(work.startTime).toLocaleTimeString();
    const endTime = new Date(work.endTime).toLocaleTimeString()
    
    const WorkTime =()=>{
         if(work.hours){
            return <>{work.hours} Hours {work.minutes} Mins {work.seconds} Secs</>
        }
       
       else if(work.minutes){
        return <>{work.minutes} Mins {work.seconds} Secs</>
        }
        
      
            return <>{work.seconds} Secs</>
        
    }
  return (
    <div className='workItem' >
            <h4 className='workItem__desc' >{work.desc}</h4>
            <h4>{startDay}</h4>
            <h4>
            {startTime}-{endTime}
            </h4>
            <h4>
            <WorkTime/>
            </h4>
    
    </div>
  )
}

export default WorkItem