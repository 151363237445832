import React from 'react'
import WorkItem from './workItem'

function WorkList({works}) {


    const sortItem=(a,b)=>{
        return b.startTime-a.startTime
    }
  return (
    <div className='workList' >
       {works.length>0 ?
       <div className='workList__Container' >
         <div className='workList__title' >
         <h4>Desc</h4>
         <h4>Date</h4>
         <h4>Start-End</h4>
         <h4>Working Time</h4>
    </div>
     <div>
     {
         works.sort(sortItem).map((work,index)=>{
         return <WorkItem key={index} work={work} />
        })
     }
     </div>
     </div>:
     <div>
        <h2>No Data</h2>
     </div>
       }
       </div>
  )
}

export default WorkList