import React from 'react'
import {BiTime} from 'react-icons/bi'
function header({totalTime}) {
    let workDays = Math.floor(totalTime/86400);
    let workHours =Math.floor((totalTime/3600));
    let workMins = Math.floor((totalTime/60)%60);
    let workSecs = Math.floor(totalTime%60);
    let WorkTime = ()=>{
        if(workDays){
            return <>{workDays} D {workHours} H {workMins} M {workSecs} S</>
        }
        else if(workHours){
            return <>{workHours} H {workMins} M {workSecs} S</>
        }
       
       else if(workMins){
            return <>{workMins} M ${workSecs} S</>
        }
        
      
            return <div>{workSecs} S</div>
        
    }
  return (
    <header>
        <h1 className='header__title'>Time Managements</h1>
        <div className='total__work' >
            <BiTime/> <WorkTime/>
        </div>
    </header>
  )
}

export default header