import React, { useEffect, useState } from 'react'

function Form({works,setWorks}) {
    const [isWorking,setIsWorking]= useState(false)
    const [isPause,setIsPaused] = useState(false)
    const [seconds,setSeconds] =useState('00')
    const [minutes,setMinutes]= useState('00')
    const [hours,setHours]= useState('0')
    const [counter,setCounter]= useState(1)
    const [startTime,setStartTime] = useState('')

    const [dateState,setDateState]=useState(new Date())
    let DaySeconds= dateState.getSeconds();
    let DayMinutes = dateState.getMinutes();
    let DayHours= dateState.getHours();

    useEffect(()=>{
        let intervalId;
        if(isWorking&& !isPause){
           intervalId= setInterval(()=>{
            setCounter(counter=>counter+1)
               
            },1000)
            let secondCounter = counter % 60;
            let minuteCounter = Math.floor((counter / 60)%60)
            let hourCounter = Math.floor(counter/3600)
             const computedSecond = String(secondCounter).length === 1 ? `0${secondCounter}`: secondCounter;
             const computedMinute = String(minuteCounter).length === 1 ? `0${minuteCounter}`: minuteCounter;
             
             setSeconds(computedSecond);
             setMinutes(computedMinute);
             setHours(hourCounter);
       
        }
        

      return ()=>  clearInterval(intervalId)
    },[isWorking,isPause,counter])

  
    const StartWork=()=>{
        setIsWorking(!isWorking)
        const startTime =new Date().getTime();
        setStartTime(startTime)
    }
    const saveWork=()=>{
        alert(`you working ${hours} hours : ${minutes} mins : ${seconds} seconds`)
        let desc = prompt('work details:')
        const endTime =new Date().getTime();
        
        setWorks([...works,{desc,hours,minutes,seconds,counter,startTime,endTime}])
        setSeconds('00');
        setMinutes('00')
        setHours('0')
        setCounter(0)
        setIsWorking(!isWorking)
    }
    
    const pauseWork=()=>{
        setIsPaused(!isPause);
    }

    const resetWork=()=>{
        setCounter(0);
        setSeconds('00');
        setMinutes('00');
        setHours('00')
    }

        useEffect(() => {
            setInterval(() => setDateState(new Date()), 1000);
          }, []);


          

  return (
    <div className='workForm' >
        {!isWorking ?<div className='innerForm'  >
        <div className='time' >current time:{DayHours}:{DayMinutes}:{DaySeconds}</div>
        <button onClick={()=>StartWork()} >Start</button>
        </div>:
        <div className='innerForm' >
        <div className='time' >working time:{hours}:{minutes}:{seconds}</div>
        <button onClick={()=>pauseWork()} >Stop</button>
        <button onClick={()=>resetWork()} >Reset</button>
        <button onClick={()=>saveWork()} >Save</button>
        </div>}
    </div>
  )
}

export default Form

//Math.floor(secs/86400),'days', Math.floor(secs/3600)%24,'hours',Math.floor(secs/60)%60,'mins',Math.floor(secs%60),'secs')